import { DrupalParagraph } from "next-drupal"
import classNames from "classnames";

import config from "site.config";
import { processHTML } from "lib/utils";

interface ParagraphColumnProps {
    paragraph: DrupalParagraph
}

export function ParagraphColumn({ paragraph }: ParagraphColumnProps) {
    if (config.debug) {
        console.log('ParagraphTab');
        console.log(paragraph)
    }
    return (
        <>
            <h3>{paragraph.field_title}</h3>
            <div key={paragraph.id} dangerouslySetInnerHTML={{ __html: processHTML(paragraph.field_html?.processed) }} className={classNames("wysiwyg",paragraph.type)} />
        </>
    )
}
