import { DrupalParagraph } from "next-drupal"
import classNames from "classnames";

import config from "site.config";
import { processHTML } from "../../lib/utils";

interface ParagraphTabProps {
    paragraph: DrupalParagraph,
    index: number,
    selected: number
}

export function ParagraphTab({ paragraph, index, selected }: ParagraphTabProps) {
    if (config.debug) {
        console.log('ParagraphTab');
        console.log(paragraph)
    }
    return (
        <div key={paragraph.id} id={'tab-'+index} dangerouslySetInnerHTML={{ __html: processHTML(paragraph.field_html?.processed) }} className={classNames("wysiwyg",paragraph.type, index == selected ? "active": undefined) } />
    )
}
