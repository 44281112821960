import * as React from "react";

const supportedTypes = {
    number: 'number',
    hidden: 'hidden',
    email: 'email',
    search: 'search',
    tel: 'tel',
    url: 'url',
    textfield: 'text',
    text: 'text',
    checkbox: 'checkbox',
    radio: 'radio'
};

export function WebformField({element}) {
    if (!(element['#type'] in supportedTypes)) {
        console.warn(
            `${element['#type']} which was used on ${element['#webform_key']} is not supported by WebformText.`,
        );
    }

    switch( supportedTypes[element['#type']] ){
        case 'checkbox':
        case 'radio':
            return (
                <>
                    <div key={"form_field_"+element['#name']} id={"form_field_"+element['#name']+"_wrapper"}>
                        <input
                            type={element['#type']}
                            name={element['#name']}
                            disabled={element['#disabled']}
                            hidden={!(element['#access'] ? element['#access'] : true)}
                            required={element['#required']}
                            readOnly={element['#readonly']}
                            id={element['#id']}
                            value={element['#return_value']}
                        />
                        <label>{element['#title']}</label>
                    </div>
                </>
            );

        default:
            return (
                <>
                    <div key={"form_field_"+element['#name']} id={"form_field_"+element['#name']+"_wrapper"} >
                    <input
                        type={supportedTypes[element['#type']] ?? element['#type']}
                        name={element['#name']}
                        disabled={element['#disabled']}
                        hidden={!element['#access']}
                        required={element['#required']}
                        readOnly={element['#readonly']}
                        placeholder={element['#title']}
                        id={element['#id']}
                    />
                    </div>
                </>
            );
    }
}


