import { DrupalNode } from "next-drupal"
import { useTranslation } from "next-i18next";
import Link from "next/link"

import config from "site.config";
import { formatDate } from "lib/utils"

interface NodeArticleTeaserProps {
    node: DrupalNode
}

export function NodeArticleTeaser({ node, ...props }: NodeArticleTeaserProps) {
    if (config.debug) {
        console.log("NodeArticleTeaser")
        console.log(node)
    }

    const { t } = useTranslation('article');

    let category_label = (typeof node?.field_color?.name === 'undefined' ? 'NONE' : node.field_color.name);
    let link = node.path?.alias?node.path.alias:'/node/'+node.drupal_internal__nid;
    return (
        <Link href={link} className="news-item-inner" {...props}>
            <div className="news-item-box">
                {category_label != '' && <span className="news-item-category">{category_label}</span> }
                <h2 className="news-item-label">{node.title}</h2>
            </div>
            <div className="news-item-info">
                <span className="news-item-date">{formatDate(node.created)}</span>
                <p className="news-item-text">{node.field_short_perex}</p>
                <span className="news-item-btn">{t("article_detail")}</span>
            </div>
        </Link>
    )
}
