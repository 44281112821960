import * as React from "react"
import { DrupalMenuLinkContent } from "next-drupal"
import { Suspense } from "react";

import Loading from "../../pages/Loading";
import { FooterStudie } from "./footer--studie";

export interface LayoutStudieProps {
    menus: {
        footer: DrupalMenuLinkContent[]
    }
    children?: React.ReactNode
}

export function LayoutStudie({ menus, children }: LayoutStudieProps) {
    return (
        <>
            <main className="studies-layout">
                <div className="container page-studies">
                    <Suspense fallback={<Loading />}>{children}</Suspense>
                    <FooterStudie footer={menus.footer}/>
                </div>
            </main>
        </>
    )
}
