import Image from "next/image"
import {DrupalParagraph} from "next-drupal"
import { useState, useEffect } from 'react';
import classNames from "classnames";

import { drupalPublic } from "lib/drupal";
import { absolutePublicUrl } from "lib/utils";

interface ParagraphBpImageProps {
    paragraph: DrupalParagraph
}

export function ParagraphBpImage({ paragraph }: ParagraphBpImageProps) {
    const [imageData, setImageData] = useState({
        alt : "",
        height: 480,
        width: 768,
        url : ""
    });
    const [loading, setLoading] = useState(true);

    const classes = classNames(
        "bp_par--wrapper",
        paragraph.field_par_set_classes,
        paragraph.field_par_set_color
    );

    useEffect(() => {
        async function fetchData() {
            try {
                const image = await drupalPublic.getResource(
                    "media--image",
                    paragraph.field_image.id
                );
                const file = await drupalPublic.getResource(
                    "file--file",
                    image.field_media_image.id
                );
                setImageData({
                    alt: image.field_media_image.resourceIdObjMeta?.alt,
                    width: image.field_media_image.resourceIdObjMeta?.width,
                    height: image.field_media_image.resourceIdObjMeta?.height,
                    url: file?.uri?.url
                });
                setLoading(false);
            } catch (error) {
                console.error('Image loading error', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [paragraph.field_image]);

    return (
        <div key={paragraph.id}  className={classNames("wysiwyg", paragraph.type, "bp_par")}>
            <div className={classes}>
                <div className="bp_par--container">
                    {loading ? (
                        <div className="img-placeholder"></div>
                    ) : (
                        <Image
                            src={absolutePublicUrl(imageData.url)}
                            width={imageData.width}
                            height={imageData.height}
                            alt={imageData.alt}
                        />
                    )}
                </div>
            </div>
        </div>

    )
}


