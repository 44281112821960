import { DrupalNode } from "next-drupal"
import Image from "next/image";
import {absolutePublicUrl, processHTML} from "../../lib/utils";
import {useTranslation} from "next-i18next";
import config from "site.config";
import Link from "next/link";
import {FormEvent, useState} from 'react'
import SpinnerLoading from "../../pages/Loading_small";

interface NodeStudieProps {
    node: DrupalNode
}

export function NodeStudie({ node }: NodeStudieProps) {
    if (config.debug) {
        console.log('NodeStudie');
        console.log(node)
    }

    const { t } = useTranslation('studie');
    const [ loading, setLoading ] = useState(false)
    const [showForm, setShowForm] = useState(true)

    let form_inputs = [
        {name: "name", type: "text", validation: {required: true}},
        {name: "job", type: "text", validation: {required: true}},
        {name: "organization", type: "text", validation: {required: true}},
        {name: "email", type: "email", validation: {required: true}},
        {name: "studie", type: "hidden", value: node.title, validation: null},
    ]

    const handleSubmit = async (event: FormEvent) => {
        // Stop the form from submitting and refreshing the page.
        event.preventDefault()
        setLoading(true);

        // Cast the event target to an html form
        const form = event.target as HTMLFormElement

        // Get data from the form.
        let data = {};
        form_inputs.forEach(function(input){
            data[input.name] = form[input.name].value;
        });

        await fetch(`${config.drupalBaseUrl}/session/token`).then(result => {
            if (200 === result.status) {
                const csrfToken = result.body as unknown as string;
                fetch(`${config.drupalBaseUrl}/webform_rest/submit`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": csrfToken
                    },
                    body: JSON.stringify({
                        webform_id: "studie",
                        name: data["name"],
                        job: data["job"],
                        organization: data["organization"],
                        studie: data["studie"],
                        email: data["email"]
                    })

                }).then(result => {
                    if( 200 == result.status) {
                        window.location.href = config.drupalBaseUrl+node.field_file_2.uri.url;
                        setShowForm(false)
                        setLoading(false)
                    }
                })
            }
        });

    }

    return (
        <>
            <section className="studies-header">
                <ul className="studies-header-partners">
                    {node.field_ogilvy_logo?.field_media_image?.uri?.url && (
                        <li>
                            <Image
                                src={absolutePublicUrl(node.field_ogilvy_logo.field_media_image.uri.url)}
                                width={node.field_ogilvy_logo.field_media_image.resourceIdObjMeta.width}
                                height={node.field_ogilvy_logo.field_media_image.resourceIdObjMeta.height}
                                alt={node.field_ogilvy_logo.field_media_image.resourceIdObjMeta?.alt} />
                        </li>
                    )}

                    {node.field_logo?.uri?.url && (
                        <li>
                            <Image
                                src={absolutePublicUrl(node.field_logo.uri.url)}
                                width={node.field_logo.resourceIdObjMeta.width}
                                height={node.field_logo.resourceIdObjMeta.height}
                                alt={node.field_logo.resourceIdObjMeta.alt} />
                        </li>
                    )}
                </ul>

                <h1>{node.title}</h1>
            </section>

            <section data-aos="fade-right" className="studies-image-text">
                <div className="studies-image-text-inner">
                    {node.field_image?.uri.url && (
                        <Image
                            src={absolutePublicUrl(node.field_image.uri.url)}
                            width={node.field_image?.resourceIdObjMeta.width}
                            height={node.field_image?.resourceIdObjMeta.height}
                            alt={node.field_image?.resourceIdObjMeta?.alt} />
                    )}
                    {node.body?.processed && (
                        <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: processHTML(node.body?.processed) }} />
                    )}
                </div>
            </section>
            <section data-aos="fade-right" className="studies-press-release">
                <h2>{t("press-release.download_studie")}</h2>
                <Link href={absolutePublicUrl(node.field_file.uri.url)} className="btn-main">{t("press-release.tiskova_zprava")}</Link>
            </section>

            <section data-aos="fade-right" className="studies-study-form">
                {showForm ?
                    (
                        <>
                            <h2>{t("form.title")}</h2>
                            <div className="studies-study-form-inner">
                                <div className="wysiwyg" dangerouslySetInnerHTML={{__html: t("form.text")}} />
                                <form onSubmit={handleSubmit}>
                                    {
                                        form_inputs.map((element) => {
                                            let name = element.name;
                                            let input_name = ""+name+"";
                                            let input_id = "reader_form_"+name;
                                            return (<input
                                                type={element.type}
                                                name={input_name}
                                                id={input_id}
                                                key={input_id}
                                                placeholder={t("form.inputs."+name+".placeholder")}
                                                value={element?.value}
                                                required={element.validation?.required}
                                            />)
                                        })
                                    }
                                    <div className="studies-study-form-info" dangerouslySetInnerHTML={{ __html: t("form.info") }}/>
                                    <button type="submit" className="btn-main" disabled={loading}>{t("form.submit")}</button>
                                </form>
                            </div>
                        </>
                    ) :
                    (<div className="studies-study-form-inner"><a href={config.drupalBaseUrl+node.field_file_2.uri.url} target="_blank" className="btn-main">{t("STUDIE")}</a></div>)
                }
                {loading && <SpinnerLoading/>}
            </section>

            <section data-aos="fade-right" className="studies-contact">
                <h2>{t("contact.title")}</h2>
                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html:t("contact.text")}}>
                </div>
            </section>
        </>
    )
}
