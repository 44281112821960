import Link from "next/link"
import { DrupalMenuLinkContent } from "next-drupal"
import React, { useEffect, useMemo } from "react";
import Cookies from "js-cookie";

import { CookieConsent } from "components/cookie-consent";

interface FooterStudieProps {
    footer: DrupalMenuLinkContent[]
}

export function FooterStudie({ footer }: FooterStudieProps) {

    /**
     * Cookie Consent functions - START
     */
    const [showCookieBar, setShowCookieBar] = React.useState<Boolean>(true)
    const openCookieConsent = () => {
        setShowCookieBar( true);
    }
    const closeCookieConsent = () => {
        setShowCookieBar( false);
    }
    const cookie_names = useMemo(() => { return {
        marketing: 'marketing-cookie',
        analytical: 'analytical-cookie'
    }; }, []);
    useEffect(() => {
        for (const [cookie_type, cookie_name] of Object.entries(cookie_names)) {
            if(typeof Cookies.get(cookie_name) != 'undefined'){
                setShowCookieBar(false);
            }
        }
    }, [cookie_names]);
    /**
     * Cookie Consent function - END
     */

    return (
        <>
        <div className="studies-footer">
            <div className="studies-footer-inner">
                <a>Copyright 2023 Ogilvy</a>
                {footer.map((link) => {
                    if (link.url == '#cookie') {
                        return (<button key={link.id} id="cookies-bar--btn-open"
                                    onClick={(e) => openCookieConsent()}>
                            {link.title}
                        </button>)
                    } else {
                        return (<Link href={link.url} passHref key={link.id}>
                            {link.title}
                        </Link>)
                    }
                })}
            </div>
        </div>
        <CookieConsent closeFunction={closeCookieConsent} cookie_names={cookie_names} showCookieBar={showCookieBar}/>
    </>
    )
}
