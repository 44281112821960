import * as React from "react"
import { Suspense } from "react";

import Loading from "../../pages/Loading";

export interface LayoutMicrositeProps {
    children?: React.ReactNode
}

export function LayoutMicrosite({ children }: LayoutMicrositeProps) {
    return (
        <>
            <Suspense fallback={<Loading />}>{children}</Suspense>
        </>
    )
}
