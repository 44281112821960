import Image from "next/image"
import { DrupalParagraph } from "next-drupal"
import { useState, useEffect } from 'react';

import { absolutePublicUrl } from "lib/utils"
import { drupalPublic } from "lib/drupal"

interface ParagraphImageProps {
    paragraph: DrupalParagraph
}

export function ParagraphImage({ paragraph }: ParagraphImageProps) {
    const [imageData, setImageData] = useState({
        alt : "",
        url : ""
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const image = await drupalPublic.getResource(
                    "media--image",
                    paragraph.field_image.id
                );
                const file = await drupalPublic.getResource(
                    "file--file",
                    image.field_media_image.id
                );
                setImageData({
                    alt: image.field_media_image.resourceIdObjMeta?.alt,
                    url: file?.uri?.url
                });
            } catch (error) {
                console.error('Image loading error', error);
            }
        };

        fetchData();
    }, [paragraph.field_image]);


    return (
        <figure className="my-4" key={paragraph.id}>
            <Image
                src={absolutePublicUrl(imageData.url)}
                width={768}
                height={480}
                alt={imageData.alt}
            />
        </figure>
    )
}


